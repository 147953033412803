<template>
    <div>
        <el-form class="el-form-search" label-width="120px" style="background: #fff;">
            <el-form-item label="员工姓名：">
                <el-select size="small" v-model="form.keyword" clearable>
                    <el-option
                        v-for="item in staff_list"
                        :key="item.id"
                        :label="item.service_name"
                        :value="item.service_name"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="起始日期：">
                <el-date-picker
                    v-model="form.start_time"
                    size="small"
                    type="date"
                    :clearable="false"
                    value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item> -->
            <el-form-item label-width="40px" label=" ">
                <el-button type="primary" size="small" @click="getSchedulList">搜索</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
            <el-form-item label-width="40px" label=" " style="flex:1;white-space:nowrap;">
                <div style="font-size: 12px;color:#F32C2C;">提示：当前为一个星期的排班，支持多个星期排班，请注意合理排班，避免出现员工未排班，用户无法预约问题</div>
            </el-form-item>
            <el-form-item label-width="120px" label=" " style="margin-right:40px;">
                <el-button size="small" @click="toSet" plain>排班设置</el-button>
            </el-form-item>
        </el-form>
        <div class="table">
            <table>
                <thead>
                    <tr>
                        <th class="column1" >
                            <img class="arrow" @click="arrowCheck(- 7*24*60*60)" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/beauty/left_arrow1.png" style="margin-right:28px;">
                        </th>
                        <th v-for="(item,index) in week" :key="index" align="center" class="column2">
                            <el-checkbox
                                :indeterminate="isIndeterminate[index]"
                                v-model="checkAll[index]"
                                @change="handleCheckAllChange($event,index)"
                                v-if="item.scheduling.some(s=>s.staff.length==0)"></el-checkbox>
                            {{item.date}}（{{item.w | dealweek}}）</th>
                        <th class="column3">
                            <img class="arrow" @click="arrowCheck(7*24*60*60)" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/beauty/right_arrow1.png">
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(r_item,r_index) in appointment_time_frame"
                        :key="r_index"
                        class="rows">
                        <td align="center" class="column1">
                            <div class="columns1 cell">
                                 <div>{{r_item.split('-')[0]}}</div>
                                <div>-</div>
                                <div>{{r_item.split('-')[1]}}</div>
                            </div>
                        </td>
                        <td v-for="(item,index) in week" :key="index"  class="column2">
                            <div class="cell columns2"
                            :style="{'background':item.scheduling[r_index].staff.length>0?'linear-gradient(90deg, #2FD5FE, #34A6FF)':'#fff'}">
                                <el-checkbox v-model="item.scheduling[r_index].check" v-if="item.is_able && item.scheduling[r_index].staff.length==0" @change="changeCheckbox(index)"></el-checkbox>
                                <el-button type="text" v-if="item.is_able && item.scheduling[r_index].staff.length>0" @click="editSchedul(item.date,r_item,item.scheduling[r_index].date_key,item.scheduling[r_index].staff)" class="edit_btn">编辑</el-button>
                                <div class="main">
                                    <template v-if="item.scheduling[r_index].staff.length==0 && item.is_able">
                                        <img src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/beauty/add.png" class="addimage" @click="addSchedul(item.date,r_item,item.scheduling[r_index].date_key)">
                                       <div @click="addSchedul(item.date,r_item,item.scheduling[r_index].date_key)"
                                        style="font-weight: bold;color:#409EFF;cursor: pointer;">点击添加人员安排</div>
                                    </template>
                                    <template v-if="item.scheduling[r_index].staff.length>0">
                                        <div class="staff staff_text">
                                            <div>
                                                <span v-for="(s,i) in item.scheduling[r_index].staff" :key="s.id">{{i==0?'':'，'}}{{s.service_name}}</span>
                                            </div>
                                        </div>
                                        <div class="staff_text" style="margin-bottom:20px;">{{item.scheduling[r_index].staff.length}}人</div>
                                    </template>
                                </div>
                            </div>
                        </td>
                        <td class="column3"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="footer" v-if="week.some(i=>i.scheduling.some(j=>j.staff.length==0))">
            <el-checkbox :indeterminate="isIndeterminateall" v-model="checkAllall"
            @change="handleCheckAllallChange" style="margin-right:10px;"></el-checkbox>
            <el-button type="primary" @click="batchSchedul">批量排班</el-button>
        </div>

        <!-- 排班 -->
        <el-dialog :title="type=='add'?'添加排班员工':'编辑排班员工'" :visible.sync="dialogVisible" width="54%">
            <div class="schedule">
                <div class="date">
                    <span style="margin-right:10px;">排班日期：</span>
                    <span v-for="(item,index) in date_line_date" :key="item" class="date_item">{{index>0?'，':''}}{{item}}</span>
                </div>
                <div v-for="(item,index) in date_line_line" :key="item">
                    <div class="line">
                        <span style="margin-right:10px;">排班时间：</span>
                        <span style="font-weight: bold;">{{item.line}}</span>
                    </div>
                    <div class="staff">
                        <div class="staff_box staff_btn" plain @click="addStaff(index)">
                            <i class="el-icon-circle-plus-outline"></i>添加员工
                        </div>
                        <div v-for="(s,i) in item.staffs" :key="s.id" class="staff_box staff_item">
                            <i class="el-icon-error" @click="delStaff(index,i)"></i>
                            {{s.service_name}}
                        </div>
                    </div>
                </div>
            </div>
             <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmSchedul">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 选择员工 -->
        <el-dialog title="选择员工" :visible.sync="showSelectStaff" width="54%">
            <el-table :data="select_staff_list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="service_name" label="员工姓名" align="center"></el-table-column>
                <el-table-column prop="service_phone" label="员工电话" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="chooseStaff(scope.row)" type="text" size="small">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Paging :total="staff_total_number" :page="staff_form.page" :pageNum="staff_form.rows" @updatePageNum="updateDataStaff"></Paging>
        </el-dialog>
    </div>
</template>

<script>
    import {getDateformat} from '@/util/getDate';
    import Paging from '@/components/paging';
    export default {
        components: {
            Paging,
        },
        data() {
            return {
                form: {
                    keyword: '',
                    start_time: getDateformat(new Date() / 1000).split(' ')[0]
                },
                staff_list: [],
                appointment_time_frame: [],
                week: [],

                isIndeterminate: Array(7).fill(false),
                checkAll: Array(7).fill(false),

                dialogVisible: false,
                date_line_date: [],
                date_line_line: [],
                date_line:[],

                showSelectStaff:false,
                select_staff_list:[],
                staff_form:{
                    page:1,
                    rows:10
                },
                staff_total_number:0,
                current_index:'',

                isIndeterminateall:false,
                checkAllall:false,

                type:'',
            };
        },
        filters : {
            dealweek(val) {
                switch (val) {
                    case 1:
                        return '周一'
                        break
                    case 2:
                        return '周二'
                        break
                    case 3:
                        return '周三'
                        break
                    case 4:
                        return '周四'
                        break
                    case 5:
                        return '周五'
                        break
                    case 6:
                        return '周六'
                        break
                    case 7:
                        return '周日'
                        break
                    default:
                        return ''
                        break
                }
            }
        },
        created() {
            this.getStaff()
            this.getSchedulList()
        },
        methods : {
            getStaff() {
                this.$axios.post(this.$api.beauty.stafflist).then(res => {
                    if (res.code == 0) {
                        // this.staff_list = res.result.list;
                        let total = res.result.total
                        this.$axios.post(this.$api.beauty.stafflist, {
                                page: 1,
                                rows: total
                            })
                            .then(res2 => {
                                if (res2.code == 0) {
                                    this.staff_list = res2.result.list;
                                } else {
                                    this.$message.error(res2.msg);
                                }
                            });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            cancelSearch() {
                this.form = {
                    keyword: '',
                    start_time: getDateformat(new Date() / 1000).split(' ')[0]
                }
                this.getSchedulList()
            },
            getSchedulList() {
                this.isIndeterminate = Array(7).fill(false)
                this.checkAll = Array(7).fill(false)
                this.isIndeterminateall = false;
                this.checkAllall = false
                this.$axios.post(this.$api.beauty.schedulList, this.form).then(res => {
                    if (res.code == 0) {
                        this.appointment_time_frame = res.result.appointment_time_frame;

                        let week = res.result.week;
                        week.forEach(item=>{
                            item.scheduling.forEach(s=>s.check = false)
                        })
                        this.week = week
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            handleCheckAllChange(val, index) {
                this.week[index].scheduling.forEach(s=>{
                    if(s.staff.length==0){
                        s.check = val
                    }
                })
                this.isIndeterminate[index] = false
            },
            handleCheckAllallChange(val){
                this.week.forEach(item=>{
                    item.scheduling.forEach(s=>{
                        if(s.staff.length==0){
                            s.check = val
                        }
                    })
                })
                this.checkAll = Array(7).fill(val)
                this.isIndeterminate = Array(7).fill(false)
                this.isIndeterminateall = false;
            },
            changeCheckbox(index){
                let arr_column_count = this.week[index].scheduling.filter(s=>s.check).length
                let arr_all_count = this.week.reduce((total,item)=>{return total+item.scheduling.filter(s=>s.check).length},0)
                this.checkAll[index] = arr_column_count == this.week[index].scheduling.length
                this.isIndeterminate[index] = arr_column_count > 0 && arr_column_count < this.week[index].scheduling.length
                this.checkAllall = arr_all_count == this.week[index].scheduling.length*7
                this.isIndeterminateall = arr_all_count > 0 && arr_all_count < this.week[index].scheduling.length*7
            },
            arrowCheck(time) {
                let start_time = new Date(this.form.start_time) / 1000 + time
                this.form.start_time = getDateformat(start_time).split(' ')[0]
                this.getSchedulList()
            },
            confirmSchedul() {
                if(this.type=='add'){
                    if(this.date_line_line.every(item=>item.staffs.length==0)){
                        return this.$message.warning('请添加员工');
                    }

                    let arr = []
                    this.date_line.forEach(item=>{
                        let staff = this.date_line_line.find(l=>l.line==item.line).staffs
                        if(staff.length>0){
                            arr.push({
                                date:item.date,
                                line:JSON.stringify(item.date_key),
                                staff_ids:staff.map(s=>s.staff_id),
                            })
                        }
                    })
                    // console.log(arr)
                    this.$axios.post(this.$api.beauty.setScheduling, {date_line:arr}).then(res => {
                        if (res.code == 0) {
                            this.$message.success('添加成功');
                            this.dialogVisible = false
                            this.getSchedulList()
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }else{
                    let staff_ids = this.date_line_line[0].staffs.map(item=>item.staff_id)
                    let data = {
                        staff_ids:staff_ids.length>0?staff_ids:"",
                        date_line:{
                            date: this.date_line[0].date,
                            line:JSON.stringify(this.date_line[0].date_key)
                        }
                    }
                    // console.log(data)
                    this.$axios.post(this.$api.beauty.editScheduling, data).then(res => {
                        if (res.code == 0) {
                            this.$message.success('编辑成功');
                            this.dialogVisible = false
                            this.getSchedulList()
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }
            },
            editSchedul(date, line,date_key,staffs){
                this.date_line = []
                this.date_line.push({date, line,date_key})
                this.date_line_date = []
                this.date_line_date.push(date)
                this.date_line_line = []
                let arr = [...staffs]
                this.date_line_line.push({line,staffs:arr})
                this.type = 'edit'
                this.dialogVisible = true
            },
            addSchedul(date, line,date_key) {
                this.date_line = []
                this.date_line.push({date, line,date_key})
                this.date_line_date = []
                this.date_line_date.push(date)
                this.date_line_line = []
                this.date_line_line.push({line,staffs:[]})
                this.type = 'add'
                this.dialogVisible = true
            },
            batchSchedul(){
                let date_line = []
                let date_line_date = []
                let date_line_line = []
                this.week.forEach(item=>{
                    item.scheduling.forEach(s=>{
                        if(s.check){
                            date_line.push({
                                date:item.date,
                                line:s.date,
                                date_key:s.date_key
                            })
                            if(date_line_line.every(d=>d.line!=s.date)){
                                date_line_line.push({line:s.date,staffs:[]})
                            }
                            if(date_line_date.every(d=>d!=item.date)){
                                date_line_date.push(item.date)
                            }
                        }
                    })
                })

                if(date_line.length==0){
                    return this.$message.warning('请选择日期时间');
                }
                this.date_line = date_line
                this.date_line_date = date_line_date
                this.date_line_line = date_line_line
                this.type = 'add'
                this.dialogVisible = true
            },
            chooseStaff(row){
                this.date_line_line[this.current_index].staffs.push(row)
                this.showSelectStaff = false
            },
            async addStaff(index){
                this.current_index = index
                await this.getStaffAbleList()
                this.showSelectStaff = true
            },
            getStaffAbleList(){
                let data = Object.assign({},this.staff_form)
                let currunt_staff =[...this.date_line_line[this.current_index].staffs]
                data.staff_ids = currunt_staff.length>0?currunt_staff.map(item=>{return item.staff_id}):''
                return this.$axios.post(this.$api.beauty.selectAbleStaff, data).then(res => {
                    if (res.code == 0) {
                        this.staff_total_number = res.result.total_number
                        let list = res.result.list
                        list.forEach(item=>{
                            item.staff_id = item.id
                        })
                        this.select_staff_list = list
                    } else {
                        this.$message.error(res.result);
                    }
                })
            },
            updateDataStaff(val, status){
                if (status == 0) {
                    this.staff_form.rows = val;
                    this.getStaffAbleList();
                } else {
                    this.staff_form.page = val;
                    this.getStaffAbleList();
                }
            },
            delStaff(index,s_index){
                this.date_line_line[index].staffs.splice(s_index,1)
            },
            toSet(){
                this.$router.push({'path':'/reserve/reserveSet'})
            }
        }
    };
</script>

<style lang="less" scoped>
    .table{
        width: 100%;
        overflow-x: auto;
         font-size: 14px;
         table{
            border-collapse: collapse;
         }
        thead {
            height: 60px;
            th {
                text-align: center;
                font-weight: bold;
                color: #2C3E50;
            }
            .column1{
                background-color: #F5F6F7;
                text-align: right;
            }
            .column2{
                background-color: #F5F6F7;
            }
            .column3{
                text-align: left;
                background-color: #F5F6F7;
            }
        }
        .column1 {
            width: 150px;
            box-sizing: border-box;
            font-weight: bold;
            color: #2C3E50;
            position: sticky;
            left: 0;
            z-index: 10;
        }
        .column2 {
            width: 180px;
            box-sizing: border-box;
        }
        .column3{
            width: 28px;
        }
        tbody {
            td{
                padding: 3px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            }
            .cell{
                border-radius: 10px;
                height: 228px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .columns1{
                background-color: #fff;
            }
            .columns2 {
                padding: 20px;
                box-shadow: 0px 0px 20px 1px rgba(116, 151, 188, 0.16);
                .edit_btn{
                    padding: 0;
                    text-align: left;
                    color: #fff;
                }
            }
            .main {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .addimage{
                    width: 68px;
                    height: 68px;
                    margin-bottom: 16px;
                    cursor: pointer;
                }
                .staff {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    > div {
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                    }
                }
                .staff_text {
                    font-weight: bold;
                    color: #ffffff;
                }
            }
        }
        .arrow {
            cursor: pointer;
            width: 15px;
            height: 25px;
        }
        thead,tbody {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
    }

.schedule{
    .date {
        margin-bottom: 20px;
        color: #606266;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .date_item {
            font-weight: bold;
            font-size: 20px;
        }
    }
    .line{
        height: 40px;
        background: #F5F5F5;
        padding: 0 20px;
        display: flex;
        align-items: center;
        color: #606266;
    }
    .staff{
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .staff_box{
            width: 110px;
            height: 50px;
            border-radius: 3px;
            margin-right: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .staff_btn{
            background: #F2F9FF;
            border: 1px solid #409EFF;
            color: #409EFF;
            cursor: pointer;
            i{
                font-size: 15px;
                margin-right:6px;
            }
        }
        .staff_item{
            border: 1px solid #999999;
            position: relative;
            i{
                position: absolute;
                top: -8px;
                right: -8px;
                font-size: 16px;
                color: #F31C1C;
                cursor: pointer;
                z-index: 10;
            }
        }
    }
}

.footer{
    margin-top: 20px;
    padding: 30px 28px;
    background-color: #fff;
}

</style>
